import React, { useEffect, useState } from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useWindowDimensions from "../utils/windowSize";
import DropdownMenu from "../components/DropdownMenu";
import { ACTIVE_PAGE } from "../components/nav/utils";
import {
  DeliverableIframe,
  IframeWrapper,
  NoScrollContainer,
} from "../components/styled-components/Globals/GlobalStyledComponents";
import {
  PageNavDirection,
  PageNavPrev,
  PageNavPrevName,
  PageNavigation,
} from "../components/styled-components/Globals/PageNavigation";

const getIndex = (searchQuery: string) => {
  const urlQuery = /^(?:\?view=)(.*)/.exec(searchQuery);
  const query = urlQuery ? urlQuery[1].replaceAll("+", " ") : null;
  if (!query) return 10;

  const titles = vrLinks.map((link) => link.title);
  if (titles.includes(query))
    return titles.findIndex((title) => title === query);
  else return 0;
};

export default function VrPage() {
  const { width } = useWindowDimensions();
  const [linkIndex, setLinkIndex] = useState<number | null>(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinkIndex(getIndex(window.location.search));
    }
  }, []);

  const handleClick = (title: string, index: number) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      url.searchParams.set("view", title);
      window.history.pushState({}, "", url.toString());
      setLinkIndex(index);
    }
  };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.WALKTHROUGH}>
      <Seo
        title="Visual Capture | Robotic Imaging"
        description="Virtual reality capture services using Matterport tools. Come see the kind of virtual reality products delivered by Robotic Imaging"
      />

      <header className="site__header">
        <div className="site__header__button-container-tags">
          {vrLinks.map((link, index) => (
            <button
              key={link.title}
              onClick={() => handleClick(link.title, index)}
              disabled={linkIndex === index}
              className={`site__header__button${
                linkIndex === index ? "-disabled" : ""
              }`}
            >
              {link.title}
            </button>
          ))}
        </div>

        <DropdownMenu
          index={linkIndex}
          setIndex={setLinkIndex}
          options={vrLinks}
        />
      </header>

      <NoScrollContainer>
        <IframeWrapper>
          <DeliverableIframe
            title="virtual-walkthrough"
            src={linkIndex !== null ? vrLinks[linkIndex].url : ""}
            allowFullScreen
            allow="xr-spatial-tracking"
          />
        </IframeWrapper>

        <PageNavigation>
          <PageNavPrev to="/2D" title="Previous page">
            <div>
              <ChevronLeftIcon />
              <PageNavDirection>Previous Page</PageNavDirection>
            </div>
            {width && width >= 650 ? (
              <PageNavPrevName>2D Imaging</PageNavPrevName>
            ) : null}
          </PageNavPrev>
        </PageNavigation>
      </NoScrollContainer>
    </SiteWrapper>
  );
}

export const vrLinks = [
  {
    title: "Adaptive Reuse",
    url: "https://my.matterport.com/show/?m=yP5JaPq1AUF",
  },
  {
    title: "Drive-Thru",
    url: "https://my.matterport.com/show/?m=TXivhwHwcdd",
  },
  {
    title: "Retail",
    url: "https://my.matterport.com/show/?m=FLFP3ndTFzb",
  },
  {
    title: "Single-Family",
    url: "https://my.matterport.com/show/?m=n9KnPdW9y2s",
  },
  {
    title: "C-Store",
    url: "https://my.matterport.com/show/?m=6PVx5wEYLnZ",
  },
  {
    title: "Fashion",
    url: "https://my.matterport.com/show/?m=XbxLaYx6FnY",
  },
  {
    title: "Hotel",
    url: "https://my.matterport.com/show/?m=5ZN9qD83yyG",
  },
  {
    title: "Retail End-Cap",
    url: "https://my.matterport.com/show/?m=MWtvYAs1Rsp",
  },
  {
    title: "Interior Department Store",
    url: "https://my.matterport.com/show/?m=D8ziJxqjcm5",
  },
  {
    title: "Kitchen Renovation",
    url: "https://my.matterport.com/show/?m=nVYXPHGhVZf",
  },
  {
    title: "Above Ceiling",
    url: "https://my.matterport.com/show/?m=g1gC9Kwx8Zg&cloudEdit=1&ss=90&sr=3.06,-.38",
  },
  {
    title: "Mixed Commercial",
    url: "https://my.matterport.com/show/?m=RQhq2kkidFj",
  },
];
